import React, { PureComponent } from 'react';

export default class Counter extends PureComponent {

  render() {
    return (
      <div css = {{borderRadius:`20px`,backgroundColor:`transparent`, border:`10px dashed #7d4aa3`, marginBottom:`4rem`,'@media (max-width: 750px)':{marginLeft:`5%`, marginRight:`5%`}}}>
      <div css={{textAlign:`left`, margin:`0 10% 0 10%`,display:`inline-grid`}}>
      <h2 css = {{color:`#7d4aa3`, paddingTop:`2rem`}}> Monthly life updates </h2>
      <a css = {{color:`#7d4aa3!important`, paddingBottom:`2rem`}} href="http://go.brettbejcek.com/latest-update"><u>More current stuff on the person I am and who I am trying to become.</u></a>
      </div>
      </div>);
  }

}
